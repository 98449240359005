import React from 'react';
import { Helmet } from 'react-helmet';

import { COLOR_COUNT_0, COLOR_SEARCH_0 } from '../colors';
import { formatCountValue } from '../utils';

import { CategorySummary } from '../components/CategorySummary';
import { LineChart, DoughnutChart } from '../components/charts';
import { Row333333, Row5050 } from '../components/grid';
import { CountIcon, SearchIcon } from '../components/icons';
import { ViewMoreLink } from '../components/links';
import { BasicMetric, CountMetric, SearchMetric } from '../components/metrics';
import { Paper, PaperPadding, PaperTitle } from '../components/papers';
import { Ranking, RankingLink, RankingTable } from '../components/rankings';
import { AppLayout, SectionTitle } from '../layout';

/**
 *
 */
function OverviewListingSection(props) {
  const { listings, queries } = props.data;

  return (
    <div>
      <Row333333>
        <CountMetric value={`${formatCountValue(listings.metrics.currentListingCount)} Listings`} label="Current" />
        <CountMetric value={`${formatCountValue(listings.metrics.lifetimeListingCount)} Listings`} label="Historic" />
        <SearchMetric value={`${formatCountValue(queries.metrics.queryTotalCount)} Roles`} label="Searched" />
      </Row333333>

      <Row5050>
        <Paper>
          <PaperTitle>Job Listings Analyzed</PaperTitle>
          <PaperPadding>
            <LineChart data={listings.charts.lineListingCount} />
          </PaperPadding>
        </Paper>

        <Paper>
          <PaperTitle>Job Listings by Role</PaperTitle>
          <PaperPadding>
            <DoughnutChart data={queries.charts.doughnutTotalListings} />
          </PaperPadding>
        </Paper>
      </Row5050>
    </div>
  );
}

/**
 *
 */
function OverviewStateSection(props) {
  const { currentRankingSegment, metrics } = props.data;

  return (
    <>
      <Row333333>
        <BasicMetric
          value={`${metrics.currentStateCount} States`}
          label="Searched"
          icon={SearchIcon}
          color={COLOR_SEARCH_0}
        />

        <BasicMetric
          value={`${formatCountValue(metrics.currentListingCount)} Listings`}
          label="Current"
          icon={CountIcon}
          color={COLOR_COUNT_0}
        />

        <BasicMetric
          value={`${formatCountValue(metrics.lifetimeListingCount)} Listings`}
          label="Historic"
          icon={CountIcon}
          color={COLOR_COUNT_0}
        />
      </Row333333>

      <Paper>
        <PaperTitle>Top 5 States for Tech Jobs</PaperTitle>
        <Ranking>
          <RankingTable data={currentRankingSegment} parentPath="states" />
          <RankingLink to="/states/">View All Results</RankingLink>
        </Ranking>
      </Paper>
    </>
  );
}

/**
 *
 */
function OverviewCitySection(props) {
  const { currentRankingSegment, metrics } = props.data;

  return (
    <>
      <Row333333>
        <BasicMetric
          value={`${metrics.currentCityCount} Cities`}
          label="Searched"
          icon={SearchIcon}
          color={COLOR_SEARCH_0}
        />

        <BasicMetric
          value={`${formatCountValue(metrics.currentListingCount)} Listings`}
          label="Current"
          icon={CountIcon}
          color={COLOR_COUNT_0}
        />

        <BasicMetric
          value={`${formatCountValue(metrics.lifetimeListingCount)} Listings`}
          label="Historic"
          icon={CountIcon}
          color={COLOR_COUNT_0}
        />
      </Row333333>

      <Paper>
        <PaperTitle>Top 5 Cities for Tech Jobs</PaperTitle>
        <Ranking>
          <RankingTable data={currentRankingSegment} parentPath="cities" />
          <RankingLink to="/cities/">View All Results</RankingLink>
        </Ranking>
      </Paper>
    </>
  );
}

/**
 *
 */
export function Overview(props) {
  const { appData, categories, cities, listings, queries, states } = props.pageContext;

  return (
    <AppLayout data={appData} title="Overview">
      <Helmet>
        <title>Overview | StackTrends</title>
        <meta name="description" content="" />
      </Helmet>

      <OverviewListingSection data={{ listings, queries }} />

      <SectionTitle>
        State Summary <ViewMoreLink to={`/states/`}>View Detail</ViewMoreLink>
      </SectionTitle>

      <OverviewStateSection data={states} />

      <SectionTitle>
        City Summary <ViewMoreLink to={`/cities/`}>View Detail</ViewMoreLink>
      </SectionTitle>

      <OverviewCitySection data={cities} />

      {categories.map((category) => (
        <CategorySummary data={category} key={category.details.id} />
      ))}
    </AppLayout>
  );
}

export default Overview;
